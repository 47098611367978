import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BlogDetails from '../Component/Blog/BlogDetails'
import BlogComment from '../Component/Blog/BlogComment'
import axios from 'axios'
import { domain, hostname } from '../App'
import { Helmet } from 'react-helmet'
import RightSideBlog from '../Component/RightSideBlog'


const ParticularBlog = () => {
    let { slug } = useParams()
    let [blog, setBlog] = useState()
    useEffect(() => {
        window.scrollTo(0, 0)
        if (slug) {
            axios.get(`${hostname}/blog/data/${slug}`).then((response) => {
                console.log(response.data, 'blog');
                setBlog(response.data)
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [slug])
    return (
        <div className=' mx-auto col-xl-10 '>
            {blog && <> <Helmet>
                <title> {blog.meta_titel ? blog.meta_titel : ''} </title>
                <meta name="description"
                    content={blog.meta_description ? blog.meta_description : ''} />
                <link rel="canonical" href={`${domain}/blogs/${slug}`} />
            </Helmet>
                <h1 className='d-none'> {blog.h1_tag} </h1>
                <h2 className='d-none'> {blog.h2_tag} </h2>
            </>}
            <section className='container row my-5'>
                <div className='col-lg-8 col-xl-9 '>
                    {blog && <BlogDetails data={blog} />}
                    {blog && <BlogComment id={blog.id} />}
                </div>
                <div className='col-lg-4 col-xl-3 '>
                    <RightSideBlog />
                </div>
            </section>
        </div>
    )
}

export default ParticularBlog