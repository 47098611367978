import React from 'react'
import { useNavigate } from 'react-router-dom'
import CalenderIcon from '../../Icons/CalenderIcon'
import CommentIcon from '../../Icons/CommentIcon'
import MailIcon from '../../Icons/MailIcon'
import ProfileFill from '../../Icons/ProfileFill'
import ArrowDown from '../../Icons/ArrowDown'
import ClockIcon from '../../Icons/ClockIcon'
import FacebookIcon from '../../Icons/FacebookIcon'
import TwitterIcon from '../../Icons/TwitterIcon'
import WhatsappIcon from '../../Icons/WhatsappIcon'

const BlogDetails = ({ data }) => {
    function convertDate(dateStr) {
        // Create a new Date object from the date string
        const date = new Date(dateStr);
        // Define an array of month names
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        // Get the day of the month (1-31)
        const day = date.getDate();
        // Get the month name from the months array (0-11)
        const month = months[date.getMonth()];

        // Get the full year (YYYY)
        const year = date.getFullYear();

        // Return the formatted date string
        return `${month} ${day}, ${year}`;
    }
    let navigate = useNavigate()
    return (
        <>
            {data &&
                <div className=' ' >
                    <img loading='lazy' className='rounded w-full h-[50vh] lg:h-[30vh] xl:h-[55vh] object-cover  '

                        src={data.img ? data.img : require('../../Assest/Maskdummyblog.png')} alt="Blog" />
                    {data && <p className='flex gap-2 items-center my-3 text-xs  '>
                        <span className='text-violet-800'><CalenderIcon /> </span> {convertDate(data.created_at.slice(0, 10))}
                        <span className='text-violet-800' ><ProfileFill /> </span> by Admin
                        <span className='text-violet-800' ><ClockIcon /> </span> 5 min Read
                        <span className='text-violet-800'> <CommentIcon /></span> 05 Comments
                    </p>}
                    {/* Heading 1 */}
                    {data.Main_Title &&
                        <h1 dangerouslySetInnerHTML={{ __html: data.Main_Title }}
                            className='poppins my-3 fw-semibold text-3xl'>
                        </h1>}
                    {/* Para1 */}

                    {data.Paragraph1 && <p style={{ color: '#666666', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: data.Paragraph1 }}
                        className=' '>
                    </p>}

                    {/* Content 2 */}
                    {data.Sub_Title && <h5 className='poppins fw-semibold text-xl'
                        dangerouslySetInnerHTML={{ __html: data.Sub_Title }} >

                    </h5>}
                    {data.Paragraph2 && <p style={{ color: '#666666', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: data.Paragraph2 }}
                        className=' '>
                    </p>}
                    {/* Points */}
                    <div>
                        {
                            data.Points_Heading && <h5 dangerouslySetInnerHTML={{ __html: data.Points_Heading }}
                                className='poppins fw-semibold '> </h5>
                        }
                        {data.points && data.points.map((obj) => {
                            return (
                                <p className='flex m-0 items-center fw-semibold my-3 gap-2'>
                                    <span
                                        className=' p-2 makeshadow bg-blue-500 rounded-full
                                     text-white  -rotate-90'><ArrowDown size={12} />
                                    </span>
                                    {obj.point}
                                </p>
                            )
                        })}

                    </div>

                    {/* Tet content */}
                    {data.Text_Content && data.Text_Content.map((obj) => {
                        return (
                            <section>
                                {obj.Heading != 'null' &&
                                    <h4
                                        dangerouslySetInnerHTML={{ __html: obj.Heading }}
                                        className={`my-2 text-xl ${obj.Paragraph == 'null' ? ' fw-bold' : 'fw-medium'} poppins `}>
                                    </h4>}
                                {obj.Paragraph != 'null' && <p style={{ color: '#666666', fontSize: '15px' }}
                                    className='my-2 '
                                    dangerouslySetInnerHTML={{ __html: obj.Paragraph }} >

                                </p>}
                            </section>
                        )
                    })}
                    {/* Highlight */}
                    {data.Highlight && <div className='p-3 w-10/12 my-3 mx-auto flex gap-3 rounded-e-2xl border-s-4 bg-blue-600 bg-opacity-15 bclr2 border-opacity-100 '>
                        {data.Highlight && <p dangerouslySetInnerHTML={{ __html: data.Highlight }}
                            className='inter text-sm '>

                        </p>}
                        <img loading='lazy' src={require('../../Assest/left_quotes.png')} width={50} className='h-fit ' alt="Qoutes" />
                    </div>}

                    <hr />
                    <div className='flex flex-wrap gap-3 my-3 justify-between items-center'>
                        {/* <div className='flex gap-1 h-fit w-fit max-w-1/2'>
                            Tags :
                            {
                                ['Software Development', 'Cleaning'].map((x) => (
                                    <p className='mb-0 p-1 px-3 rounded-full text-white bg-blue-600 text-sm w-fit '> {x} </p>
                                ))
                            }
                        </div> */}
                        <div className='w-fit flex gap-3 ms-auto  items-center'>
                            <p className='fw-semibold mb-0  ' >Share :</p>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`Check out this link: ${window.location}`)}`}
                                target='_blank' className='text-decoration-none ' >

                                {/* <img loading='lazy' src={facebookShare} className='h-fit cursor-pointer ' alt="Facebook Share" /> */}
                                <FacebookIcon />
                            </a>
                            <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out this link: ${window.location}`)}`}
                                target='_blank' className='text-decoration-none '>
                                {/* <img loading='lazy' src={twitterShare} className='h-fit cursor-pointer ' alt="Twitter" /> */}
                                <TwitterIcon />
                            </a>
                            <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(`Check out this link: ${window.location}`)}`}
                                target='_blank' className='text-decoration-none '>
                                {/* <img loading='lazy' src={whatsappShare} className='h-fit cursor-pointer ' alt="Whatsapp" /> */}
                                <WhatsappIcon />
                            </a>
                            <a href={`mailto:?subject=${encodeURIComponent('Checkout the Link ')}&body=${encodeURIComponent(`Hi,\n\nI found this interesting link and wanted to share it with you:\n${window.location}`)}`}
                                target='_blank' className='text-decoration-none '>
                                <MailIcon size={17} /> </a>
                        </div>
                    </div>
                    <hr />
                    {/* COmmets */}
                    {/* <div style={{ backgroundColor: 'rgb(247,247,250)' }}
                        className='p-4 rounded  flex flex-col flex-sm-row sm:items-center gap-3'>
                        <img loading='lazy' src={require('../../Assest/comment1.png')} className='w-[35px] inter h-fit sm:w-[100px] ' alt="comment" />
                        <div className=''>
                            <p className='mb-0'>Author</p>
                            <p className='text-xl fw-semibold mb-0'>Brooklyn Simmons </p>
                            <p className='text-slate-600 mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit porro amet quaerat fugit
                                atque modi reiciendis accusantium vitae repudiandae eligendi!</p>
                        </div>
                    </div> */}
                    {/* <p className='fw-semibold poppins text-2xl my-2 ' >02 Comments </p> */}
                    {/* ONe more Comment */}
                    {/* <div
                        className='p-4 rounded  flex flex-col flex-sm-row sm:items-center gap-3'>
                        <img loading='lazy' src={require('../../Assest/comment2.png')} className='w-[35px] inter h-fit sm:w-[100px] ' alt="comment" />
                        <div className=''>
                            <p className='mb-0 flex'>Author <span className='ms-auto text-xs  text-slate-600'>20 July,2024 </span></p>
                            <p className='text-xl fw-semibold mb-0'>Brooklyn Simmons </p>
                            <p className='text-slate-600 mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit porro amet quaerat fugit
                                atque modi reiciendis accusantium vitae repudiandae eligendi!</p>
                            <button style={{ backgroundColor: 'rgb(247,247,250)' }} className='p-1 px-3 rounded uppercase text-sm text-blue-700 '>replay </button>
                        </div>
                    </div> */}
                    {/* <hr /> */}
                    {/* <div
                        className='p-4 rounded flex flex-col flex-sm-row sm:items-center gap-3'>
                        <img loading='lazy' src={require('../../Assest/comment2.png')} className='w-[35px] inter h-fit sm:w-[100px] ' alt="comment" />
                        <div className=''>
                            <p className='mb-0 flex'>Author <span className='ms-auto text-xs  text-slate-600'>20 July,2024 </span></p>
                            <p className='text-xl fw-semibold mb-0'>Brooklyn Simmons </p>
                            <p className='text-slate-600 mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit porro amet quaerat fugit
                                atque modi reiciendis accusantium vitae repudiandae eligendi!</p>
                            <button style={{ backgroundColor: 'rgb(247,247,250)' }} className='p-1 px-3 rounded uppercase text-sm text-blue-700 '>replay </button>
                        </div>
                    </div> */}

                </div>
            }
        </>
    )
}

export default BlogDetails